<template>
  <ul class="terms">
    <li>
      <a href="https://company.wizards.com/tou">{{ $root.$t('footer__utils__terms') }}</a>
    </li>
    <li>
      <a href="https://company.wizards.com/conduct">{{ $root.$t('footer__utils__code_of_conduct') }}</a>
    </li>
    <li>
      <a href="https://company.wizards.com/policy">{{ $root.$t('footer__utils__privacy_policy') }}</a>
    </li>
    <li>
      <a href="https://support.wizards.com/">{{ $root.$t('footer__utils__customer_support') }}</a>
    </li>
    <li>
      <router-link to="cookies">
        {{ $root.$t('footer__utils__cookies') }}
      </router-link>
    </li>
    <li>
      <a href="https://company.wizards.com/fancontentpolicy">{{ $root.$t('footer__utils__fan_content_policy') }}</a>
    </li>
    <li>
      <a href="https://company.wizards.com/legal/wizards-coasts-privacy-policy#donotsell">{{ $root.$t('footer__utils__donotsell') }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Terms'
}
</script>

<style lang="scss">
  .terms {
    background-color: $footer-legal-bg-color;
    list-style-type: none;
    margin: 0;
    padding: 8px 15px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $fontweight-semibold;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    li {
      margin: 0;
      white-space: nowrap;
    }
    a {
      color: $footer-legal-fg-color;
    }
  }
</style>
